var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('scrollable-container',[_c('placeholder',{directives:[{name:"show",rawName:"v-show",value:(_vm.state === _vm.reportEnum.requestAwaiting),expression:"state === reportEnum.requestAwaiting"}],attrs:{"text":_vm.text},on:{"buttonClick":_vm.buildReport}}),_c('loader',{directives:[{name:"show",rawName:"v-show",value:(_vm.state === _vm.reportEnum.tableLoading),expression:"state === reportEnum.tableLoading"}]}),_c('container',{directives:[{name:"show",rawName:"v-show",value:(_vm.state === _vm.reportEnum.tableReady),expression:"state === reportEnum.tableReady"}],class:[
    ( _obj = {}, _obj[_vm.$style.message] = _vm.state === _vm.reportEnum.requestAwaiting, _obj ),
    _vm.$style.scrollableContent ],attrs:{"id":"printable"}},[(_vm.isEmpty)?_c('div',{class:_vm.$style.emptyResult},[_vm._v(" Нет данных за указанный период ")]):_vm._e(),(!_vm.isEmpty)?_c('div',[_c('div',{class:_vm.$style.table},[_c('div',{class:_vm.$style.th},_vm._l((_vm.columns),function(column,index){return _c('div',{key:column + index,class:_vm.$style.td,style:({ flexGrow: _vm.columns[index].width })},[_vm._v(" "+_vm._s(column.name)+" ")])}),0),_vm._l((_vm.tableArray),function(user,index){
  var _obj;
return _c('div',{key:user + index},[(user.messagesNum)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(user.isVisible),expression:"user.isVisible"}],class:[_vm.$style.tr, _vm.$style.user, _vm.$style.group],style:(_vm.indent(user.depth, false)),on:{"click":function($event){return _vm.expand(user)}}},[_c('div',{class:[
              _vm.$style.expandButton,
              ( _obj = {}, _obj[_vm.$style.expanded] = user.isExpanded, _obj ) ]}),_c('div',{class:_vm.$style.td},[(!_vm.isFolder(user))?_c('div',{class:_vm.$style.bold},[_vm._v(" "+_vm._s(user.nameUser)+" ")]):_vm._e(),(_vm.isFolder(user))?_c('div',{class:_vm.$style.bold},[_vm._v(" "+_vm._s(user.name)+" ")]):_vm._e(),_c('div',{class:_vm.$style.text},[_vm._v(" (Суммарно - ")]),_c('div',{class:_vm.$style.bold},[_vm._v(" "+_vm._s(user.messagesNum)+" ")]),_c('div',{class:_vm.$style.text},[_vm._v(" ) ")])])]):_vm._e(),_vm._l((user.groupedMessengers),function(group){
            var _obj;
return _c('div',{directives:[{name:"show",rawName:"v-show",value:(user.isVisible && user.isExpanded && user.messagesNum),expression:"user.isVisible && user.isExpanded && user.messagesNum"}],key:group.recipient + index},[_c('div',{class:[_vm.$style.tr, _vm.$style.group],style:(_vm.indent(user.depth, true)),on:{"click":function($event){return _vm.toggle(group)}}},[_c('div',{class:[
                _vm.$style.expandButton,
                ( _obj = {}, _obj[_vm.$style.expanded] = group.isExpanded, _obj ) ]}),_c('div',{class:_vm.$style.td},[_c('div',{class:_vm.$style.bold},[_vm._v(" "+_vm._s(_vm.getRecipient(group))+" ")]),_c('div',{class:_vm.$style.text},[_vm._v(" (Суммарно - ")]),_c('div',{class:_vm.$style.bold},[_vm._v(" "+_vm._s(group.messagesNum)+" ")]),_c('div',{class:_vm.$style.text},[_vm._v(" ) ")])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(group.isExpanded),expression:"group.isExpanded"}],class:[_vm.$style.tr, _vm.$style.colData],style:(_vm.indent(user.depth, true))},[_c('div',{class:[_vm.$style.td, _vm.$style.data],style:(_vm.getFirstColStyle(user.depth))},[_vm._v(" "+_vm._s(group['incoming'])+" ")]),_vm._l((_vm.columns.slice(1)),function(column,num){return _c('div',{key:column.name + num,class:[_vm.$style.td, _vm.$style.data],style:({
                     flexGrow: _vm.columns[num].width,
                   })},[_c('div',{style:({
                  borderRight: '0px',
                })},[_vm._v(" "+_vm._s(group[column.field])+" ")])])})],2)])})],2)})],2)]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }