<template>
  <scrollable-container>
    <placeholder v-show="state === reportEnum.requestAwaiting" :text="text" @buttonClick="buildReport" />
    <loader v-show="state === reportEnum.tableLoading" />
    <container v-show="state === reportEnum.tableReady" id="printable" :class="[
      { [$style.message]: state === reportEnum.requestAwaiting },
      $style.scrollableContent,
    ]"
    >
      <div v-if="isEmpty" :class="$style.emptyResult">
        Нет данных за указанный период
      </div>

      <div v-if="!isEmpty">
        <div :class="$style.table">
          <div :class="$style.th">
            <div v-for="(column, index) in columns" :key="column + index" :class="$style.td"
                 :style="{ flexGrow: columns[index].width }"
            >
              {{ column.name }}
            </div>
          </div>

          <div v-for="(user, index) in tableArray" :key="user + index">
            <div v-if="user.messagesNum" v-show="user.isVisible" :class="[$style.tr, $style.user, $style.group]"
                 :style="indent(user.depth, false)" @click="expand(user)"
            >
              <div :class="[
                $style.expandButton,
                { [$style.expanded]: user.isExpanded },
              ]"
              />
              <div :class="$style.td">
                <div v-if="!isFolder(user)" :class="$style.bold">
                  {{ user.nameUser }}
                </div>
                <div v-if="isFolder(user)" :class="$style.bold">
                  {{ user.name }}
                </div>
                <div :class="$style.text">
                  (Суммарно -
                </div>
                <div :class="$style.bold">
                  {{ user.messagesNum }}
                </div>
                <div :class="$style.text">
                  )
                </div>
              </div>
            </div>
            <div v-for="group in user.groupedMessengers" v-show="user.isVisible && user.isExpanded && user.messagesNum"
                 :key="group.recipient + index"
            >
              <div :class="[$style.tr, $style.group]" :style="indent(user.depth, true)" @click="toggle(group)">
                <div :class="[
                  $style.expandButton,
                  { [$style.expanded]: group.isExpanded },
                ]"
                />
                <div :class="$style.td">
                  <div :class="$style.bold">
                    {{ getRecipient(group) }}
                  </div>
                  <div :class="$style.text">
                    (Суммарно -
                  </div>
                  <div :class="$style.bold">
                    {{ group.messagesNum }}
                  </div>
                  <div :class="$style.text">
                    )
                  </div>
                </div>
              </div>

              <div v-show="group.isExpanded" :class="[$style.tr, $style.colData]" :style="indent(user.depth, true)">
                <div :class="[$style.td, $style.data]" :style="getFirstColStyle(user.depth)">
                  {{ group['incoming'] }}
                </div>
                <div v-for="(column, num) in columns.slice(1)" :key="column.name + num"
                     :class="[$style.td, $style.data]" :style="{
                       flexGrow: columns[num].width,
                     }"
                >
                  <div :style="{
                    borderRight: '0px',
                  }"
                  >
                    {{ group[column.field] }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </container>
  </scrollable-container>
</template>

<script>
import ScrollableContainer from '@/components/common/blocks/scrollable-container.vue';
import Container from '@/components/common/blocks/container.vue';
import Placeholder from '@/components/common/blocks/report-placeholder.vue';
import { getGroupedMessengerStat } from '@/api/methods/reports/internet/index';
import Loader from '@/components/common/blocks/loader.vue';

export default {
  components: {
    ScrollableContainer,
    Container,
    Placeholder,
    Loader,
  },
  data: () => ({
    name: 'Детализированный отчет по мессенджерам',
    tableArray: [],
    state: 2,
    reportEnum: Object.freeze({
      tableReady: 1,
      requestAwaiting: 2,
      tableLoading: 3,
    }),
    text: 'Для построения отчета нажмите кнопку',
    isEmpty: true,
    columns: [
      { name: 'Входящих', field: 'incoming', sort: 0, width: 1 },
      { name: 'Исходящих', field: 'outcoming', sort: 0, width: 1 },
      { name: 'Подозрительных', field: 'suspicious', sort: 0, width: 1 },
      { name: 'Звонков', field: 'voiceCall', sort: 0, width: 1 },
      { name: 'Файлов', field: 'files', sort: 0, width: 1 },
    ],
  }),
  computed: {
    dateRange() {
      return this.$store.getters['filters/formattedDateRange'];
    },
    checkedComputers() {
      return this.$store.getters['pageSpecificData/checkedComputersList'];
    },
    computersTree() {
      const tree = this.$store.getters['pageSpecificData/computersTree'];
      let treeCopy = JSON.parse(JSON.stringify(tree));

      treeCopy = this.cutUncheckedNodes(treeCopy);

      return treeCopy;
    },
  },
  watch: {
    dateRange(oldValue, newValue) {
      if (
        oldValue.dateFrom.toString() !== newValue.dateFrom.toString()
        || oldValue.dateTo.toString() !== newValue.dateTo.toString()
      ) {
        this.state = this.reportEnum.requestAwaiting;
      }
    },
    checkedComputers(oldArray, newArray) {
      if (oldArray !== newArray) {
        this.state = this.reportEnum.requestAwaiting;
      }
    },
  },

  methods: {
    getRecipient(group) {
      let name = '';
      if (!group.nickname) name = group.recipient;
      else name = `${group.nickname} (${group.recipient})`;
      return name;
    },
    getFirstColStyle(depth) {
      return {
        marginRight: `${depth * -20 - 20}px`,
        flexGrow: '1',
      };
    },
    indent(depth, isGroup) {
      if (!isGroup) {
        return {
          marginLeft: `${depth * 20}px`,
        };
      }
      return {
        marginLeft: `${depth * 20 + 20}px`,
      };
    },
    toggle(group) {
      group.isExpanded = !group.isExpanded;
    },
    changeVisibility(item, bool) {
      item.isVisible = bool;
      if (item.type === 'folder' && item.isExpanded === bool && item.children) {
        item.children.forEach((child) => this.changeVisibility(child, bool));
      }
    },
    expand(item) {
      item.isExpanded = !item.isExpanded;

      if (item.children) {
        item.children.forEach((child) => this.changeVisibility(child, item.isExpanded));
      }
    },
    isFolder(node) {
      if (node.type === 'folder') return true;
      return false;
    },
    generateArrayFromTree(node, depth) {
      node.depth = depth;

      this.tableArray.push(node);

      if (node.type === 'folder') {
        if (node.messagesNum) this.isEmpty = false;
        node.children.forEach((child) => this.generateArrayFromTree(child, depth + 1));
      }
    },
    cutUncheckedNodes(tree) {
      const childrenList = [];

      if (tree) {
        tree.forEach((node) => {
          if (node.checked) childrenList.push(node);
          else if (node.type === 'folder' && node.children) {
            node.children = this.cutUncheckedNodes(node.children);

            if (node.children.length > 0) childrenList.push(node);
          }
        });
      }

      return childrenList;
    },
    async buildReport() {
      if (
        this.dateRange.dateFrom
        && this.dateRange.dateTo
        && this.computersTree
      ) {
        this.state = this.reportEnum.tableLoading;
        await this.fetch();
      }
    },
    getGroupString(item, groups, string) {
      const group = groups.find((one) => item.parentId === one.id);
      if (group) {
        if (string.length > 0) string = `${group.name}\\${string}`;
        else string = group.name;
        if (group.parentId !== 0) this.getGroupString(group, groups, string);
        else return string;
      }
      return '';
    },
    async fetch() {
      this.reportTree = [];

      this.promise = await getGroupedMessengerStat(
        this.computersTree,
        this.dateRange.dateFrom,
        this.dateRange.dateTo,
      ).then(({ data }) => {
        if (data) {
          this.reportTree = data.result.computersTree;

          this.tableArray = [];
          this.isEmpty = true;
          this.reportTree.forEach((node) => this.generateArrayFromTree(node, 0));

          this.state = this.reportEnum.tableReady;

          const exportArray = [];

          const groups = [];
          this.tableArray.forEach((item) => {
            if (item.messagesNum && item.messagesNum > 0) {
              if (this.isFolder(item)) {
                groups.push(item);
              } else {
                const groupName = this.getGroupString(item, groups, '');
                Object.entries(item.groupedMessengers).forEach(
                  ([key, value]) => {
                    const resObj = {
                      Пользователь: item.nameUser,
                      Отдел: groupName,
                      Собеседник: key,
                    };
                    for (let j = 0; j < this.columns.length; j++) {
                      resObj[this.columns[j].name] = value[this.columns[j].field];
                    }
                    exportArray.push(resObj);
                  },
                );
              }
            }
          });

          this.$store.commit('exportData/setDetailsMessengers', { name: this.name, data: exportArray });
        } else this.state = this.reportEnum.requestAwaiting;
      });
    },
  },
};
</script>

<style lang="scss" module>
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb {
  background: $branchColor;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background: darkgray;
}

.scrollableContent {
  margin: 0 8px 0 0;
  padding: 0 12px 0 20px;
  box-shadow: $shadow;
  flex: 1;
}

.message {
  display: flex;
  justify-content: center;
  align-items: center;
}

.notScrollableContent {
  margin: 20px 8px 0 0;
  padding: 0 12px 10px 20px;
  overflow: unset;
}

.table {
  display: flex;
  flex-flow: column nowrap;
  font-weight: 400;
  line-height: 1.5;
  flex: 1 1 auto;
  word-break: break-word;
  margin-bottom: 15px;
  padding-bottom: 20px;
  border-collapse: collapse;
  width: 100%;
}

.th {
  font-weight: 700;
  background: #dbf1ff;
  word-break: break-word;
  height: 31px;
  position: sticky;
  top: -2px;
  z-index: 3;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  font-weight: 400;
}

.th>.td {
  white-space: normal;
  word-break: break-word;
  border: 1px solid $borderColor;
  align-items: center;
  text-align: center;
  color: #232326;
  letter-spacing: -0.08px;
  font-size: 13px;
  padding: 4px 4px 4px 10px;
  justify-content: center;
  cursor: default;
}

.expandButton {
  flex-shrink: 0;
  margin-left: -27px;
  margin-right: 13px;
  width: 14px;
  height: 14px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $branchColor;
  background: $white;
  z-index: 2;

  &::before,
  &::after {
    content: '';
    background-color: $blue;
    width: 8px;
    height: 2px;
    position: absolute;
    transition: 0.25s ease-out;
  }

  &::before {
    transform: rotate(90deg);
  }

  &::after {
    transform: rotate(180deg);
  }

  &.expanded {
    &::before {
      transform: rotate(0);
    }

    &::after {
      transform: rotate(0);
    }
  }
}

.tr {
  display: flex;
  flex-flow: row nowrap;
  cursor: pointer;

  &:hover {
    background: $branchColor;
  }
}

.td {
  display: flex;
  flex-flow: row nowrap;
  flex-grow: 1;
  flex-basis: 0;
  word-break: break-word;
  overflow: inherit;
  text-overflow: ellipsis;
  min-width: 0px;
  white-space: inherit;
}

.data {
  border-left: 1px solid $borderColor;
  border-top: 1px solid $borderColor;
  border-bottom: 1px solid $borderColor;
  padding-left: 5px;
  font-size: 13px;
  line-height: 14px;
  letter-spacing: -0.24px;
  padding: 8px 4px 7px 20px;
  font-weight: 300;
}

.colData :nth-child(1) {
  margin-right: -10px;
}

.colData :nth-last-child(1) {
  border-right: 1px solid #e3e3e3;
}

.group {
  background: $light-gray;
  border: 1px solid #dfdfdf;
  font-size: 13px;
  line-height: 14px;
  letter-spacing: -0.24px;
  padding-top: 4px;
  padding-bottom: 3px;
  padding-left: 20px;
  position: relative;
  margin-top: 3px;
  margin-bottom: 3px;
  display: flex;
  align-items: center;
}

.user {
  margin-top: 7px;
}

.bold {
  font-weight: 500;
  padding: 5px 3px;
}

.text {
  padding: 5px 0;
}

.emptyResult {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
</style>
